<template>
    <div class="modal" v-if="modelValue" :class="{ active: modelValue }">
        <div class="modal_container">
            <div class="modal_header border">
                <div class="modal_info">
                    <h1>Add Note</h1>
                    <div class="dropdown">
                        <i class="fas fa-ellipsis-v" @click="noteOption = !noteOption" v-click-outside="closeNoteOption"></i>
                        <div class="drp_wrapper" v-if="noteOption" :class="{ active: noteOption }">
                            <ul>
                                <li @click="templateLibrary = !templateLibrary" :class="{ active: templateLibrary }">Import Template</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <Form @submit="handleAddNotes" v-slot="{ errors }">
                        <Field autocomplete="off" name="notes" v-model="form.note" :class="{ 'has-error': errors.note }" rules="required">
                            <div class="editorWpr">
                                <redactor v-model="form.note" name="note" :default-value="editorDefaultValue" :reset-watcher="resetToDefaultWatcher" />
                            </div>
                        </Field>
                        <div class="err_message">
                            <ErrorMessage name="note" class="text-danger" />
                        </div>
                        <div v-if="templateLibrary">
                            <template-library v-model="templateLibrary" :on-import="handleTemplateImport" type="content" />
                        </div>
                        <div class="action_wpr mt-5">
                            <button :disabled="noteLoader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                            <button :disabled="noteLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="noteLoader"></i>{{ noteLoader ? ' Adding' : 'Add' }}</button>
                        </div>
                    </Form>
                  <div class="tempcard">
                    <ul>
                      <li v-for="(noteObj, n) of notes.data" :key="n">
                        <div class="tempcard_details" v-if="noteObj">
                          <h5 v-html="noteObj.note.length > 35 ? noteObj.note.substr(0, 35) + '...' : noteObj.note"></h5>
                        </div>
                        <span class="view" @click="toggleEditNote(noteObj)">
                            <i class="fas fa-edit"></i>
                        </span>
                      </li>
                    </ul>
                    <div class="pagination mt-2" v-show="notes.total">
                      <pagination v-model="noteParams.page" :pages="notes.last_page" @update:modelValue="handleNotePagination" />
                    </div>
                  </div>
                </div>
            </div>
        </div>
      <div v-if="previewNote" class="modal preview_modal right_out">
        <div class="modal_container p-0">
          <div class="modal_header">
            <div class="modal_header border-bottom px-5">
              <h1>Edit Note</h1>
              <button type="button" class="close_button" @click="previewNote = false"><i class="fas fa-times"></i></button>
            </div>
          </div>
          <div class="modal_body">
            <div class="edit_section m-0">
              <edit-note :closeModal="closeNoteModal" :note="previewNoteForm" title="Edit Note"/>
            </div>
          </div>
        </div>
      </div>
    </div>

</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'
    import axios from 'axios'

    const TemplateLibrary = defineAsyncComponent(() => import('@/components/TemplateLibrary'))
    const EditNote = defineAsyncComponent(() => import('@/pages/contact/components/profile/EditNote'))

    export default {
        name: 'SwitchTab',

        data() {
            return {
                form: {
                    note: '',
                    source: '',
                    contacts: [],
                },
                noteOption: false,
                templateType: '',
                templateLibrary: false,
                editorDefaultValue: '',
                resetToDefaultWatcher: 0,
              noteParams: {
                page: 1,
                per_page: 6,
                contact_id: 0,
              },
              previewNote: false,
              previewNoteForm: {
                id: '',
                notes: '',
                contact_id: '',
              },
            }
        },

        props: {
            modelValue: Boolean,
            contacts: [Object, Array],
            source: {
                type: String,
                default: 'bulk-action'
            },
        },

        emits: ['update:modelValue'],

        components: {
            Form,
            Field,
            ErrorMessage,
            TemplateLibrary,
          EditNote,
        },

        watch: {
            modelValue(val) {
                if (val === true) {
                    const vm = this;
                    this.noteParams.contact_id = this.contacts[0]
                    vm.resetForm();
                    document.body.classList.add('modal-open');
                  vm.getNotes(vm.noteParams);
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        computed: mapState({
                             noteLoader: state => state.contactModule.noteLoader,
                             notes: state => state.contactModule.notes
        }),

        methods: {
            ...mapActions({
                 addNote: 'contactModule/addNote',
                            getNotes: 'contactModule/getNotes',
            }),
          closeNoteModal () {
            const vm = this;

            vm.previewNote = false;
          },
          toggleEditNote (note) {
            const vm = this;

            vm.previewNoteForm.id          = note.id
            vm.previewNoteForm.notes       = note.notes
            vm.previewNoteForm.contact_id  = note.contact_id,
                vm.previewNote = true;
          },
            closeModal() {
                const vm = this;
                vm.form.status = '';
                vm.$emit('update:modelValue', false);
            },

            resetForm() {
                const vm = this;
                vm.editorDefaultValue = '';
                vm.form.contacts = vm.contacts;
                vm.form.note = '';
                vm.form.source = vm.source ? vm.source : 'bulk-action';
            },

            handleTemplateImport(template) {
                const vm = this;
                vm.form.note = template.email_content;
                vm.editorDefaultValue = template.email_content;
            },

            closeNoteOption() {
                const vm = this;
                vm.noteOption = false;
            },

            handleAddNotes(params, { setFieldError }) {
                const vm = this;
                if (vm.contacts.length) {
                    params.contact_id = vm.contacts[0];
                    params.note = this.form.note
                    params.setFieldError = setFieldError;

                    vm.addNote(params).then((result) => {
                        if (result) {
                          vm.noteParams.contact_id  = 0
                          vm.getNotes(vm.noteParams);
                           this.resetForm()
                           this.resetToDefaultWatcher += 1;
                           this.closeModal()
                        }
                    });
                } else {
                    console.error('Contact or contact.id is undefined');
                }
            },

          handleNotePagination (page) {
            const vm = this;

            vm.noteParams.page = page;
            vm.getNotes(vm.noteParams);
          },
        },
    }
</script>
<style scoped>
.modal_body {
  padding: 0 20px !important;
}
.setting_wpr {
  padding: 20px;
  flex: 0 1 640px;
  margin: 50px auto;
  border: 1px solid #ddd;
  background-color: #fff;
}
</style>
